
// 빙글빙글~
.rotate-bg {
  animation: rotate 5s infinite;
}
@keyframes rotate {
  0% { background: linear-gradient( 0deg, #45cba5, #27a39b);}
  1% { background: linear-gradient( 3.6deg, #45cba5, #27a39b);}
  2% { background: linear-gradient( 7.2deg, #45cba5, #27a39b);}
  3% { background: linear-gradient( 10.8deg, #45cba5, #27a39b);}
  4% { background: linear-gradient( 14.4deg, #45cba5, #27a39b);}
  5% { background: linear-gradient( 18deg, #45cba5, #27a39b);}
  6% { background: linear-gradient( 21.6deg, #45cba5, #27a39b);}
  7% { background: linear-gradient( 25.2deg, #45cba5, #27a39b);}
  8% { background: linear-gradient( 28.8deg, #45cba5, #27a39b);}
  9% { background: linear-gradient( 32.4deg, #45cba5, #27a39b);}
  10% { background: linear-gradient(36deg, #45cba5, #27a39b);}
  11% { background: linear-gradient(39.6deg, #45cba5, #27a39b);}
  12% { background: linear-gradient(43.2deg, #45cba5, #27a39b);}
  13% { background: linear-gradient(46.800000000000004deg, #45cba5, #27a39b);}
  14% { background: linear-gradient(50.4deg, #45cba5, #27a39b);}
  15% { background: linear-gradient(54deg, #45cba5, #27a39b);}
  16% { background: linear-gradient(57.6deg, #45cba5, #27a39b);}
  17% { background: linear-gradient(61.2deg, #45cba5, #27a39b);}
  18% { background: linear-gradient(64.8deg, #45cba5, #27a39b);}
  19% { background: linear-gradient(68.4deg, #45cba5, #27a39b);}
  20% { background: linear-gradient(72deg, #45cba5, #27a39b);}
  21% { background: linear-gradient(75.60000000000001deg, #45cba5, #27a39b);}
  22% { background: linear-gradient(79.2deg, #45cba5, #27a39b);}
  23% { background: linear-gradient(82.8deg, #45cba5, #27a39b);}
  24% { background: linear-gradient(86.4deg, #45cba5, #27a39b);}
  25% { background: linear-gradient(90deg, #45cba5, #27a39b);}
  26% { background: linear-gradient(93.60000000000001deg, #45cba5, #27a39b);}
  27% { background: linear-gradient(97.2deg, #45cba5, #27a39b);}
  28% { background: linear-gradient(100.8deg, #45cba5, #27a39b);}
  29% { background: linear-gradient(104.4deg, #45cba5, #27a39b);}
  30% { background: linear-gradient(108deg, #45cba5, #27a39b);}
  31% { background: linear-gradient(111.60000000000001deg, #45cba5, #27a39b);}
  32% { background: linear-gradient(115.2deg, #45cba5, #27a39b);}
  33% { background: linear-gradient(118.8deg, #45cba5, #27a39b);}
  34% { background: linear-gradient(122.4deg, #45cba5, #27a39b);}
  35% { background: linear-gradient(126deg, #45cba5, #27a39b);}
  36% { background: linear-gradient(129.6deg, #45cba5, #27a39b);}
  37% { background: linear-gradient(133.20000000000002deg, #45cba5, #27a39b);}
  38% { background: linear-gradient(136.8deg, #45cba5, #27a39b);}
  39% { background: linear-gradient(140.4deg, #45cba5, #27a39b);}
  40% { background: linear-gradient(144deg, #45cba5, #27a39b);}
  41% { background: linear-gradient(147.6deg, #45cba5, #27a39b);}
  42% { background: linear-gradient(151.20000000000002deg, #45cba5, #27a39b);}
  43% { background: linear-gradient(154.8deg, #45cba5, #27a39b);}
  44% { background: linear-gradient(158.4deg, #45cba5, #27a39b);}
  45% { background: linear-gradient(162deg, #45cba5, #27a39b);}
  46% { background: linear-gradient(165.6deg, #45cba5, #27a39b);}
  47% { background: linear-gradient(169.20000000000002deg, #45cba5, #27a39b);}
  48% { background: linear-gradient(172.8deg, #45cba5, #27a39b);}
  49% { background: linear-gradient(176.4deg, #45cba5, #27a39b);}
  50% { background: linear-gradient(180deg, #45cba5, #27a39b);}
  51% { background: linear-gradient(183.6deg, #45cba5, #27a39b);}
  52% { background: linear-gradient(187.20000000000002deg, #45cba5, #27a39b);}
  53% { background: linear-gradient(190.8deg, #45cba5, #27a39b);}
  54% { background: linear-gradient(194.4deg, #45cba5, #27a39b);}
  55% { background: linear-gradient(198deg, #45cba5, #27a39b);}
  56% { background: linear-gradient(201.6deg, #45cba5, #27a39b);}
  57% { background: linear-gradient(205.20000000000002deg, #45cba5, #27a39b);}
  58% { background: linear-gradient(208.8deg, #45cba5, #27a39b);}
  59% { background: linear-gradient(212.4deg, #45cba5, #27a39b);}
  60% { background: linear-gradient(216deg, #45cba5, #27a39b);}
  61% { background: linear-gradient(219.6deg, #45cba5, #27a39b);}
  62% { background: linear-gradient(223.20000000000002deg, #45cba5, #27a39b);}
  63% { background: linear-gradient(226.8deg, #45cba5, #27a39b);}
  64% { background: linear-gradient(230.4deg, #45cba5, #27a39b);}
  65% { background: linear-gradient(234deg, #45cba5, #27a39b);}
  66% { background: linear-gradient(237.6deg, #45cba5, #27a39b);}
  67% { background: linear-gradient(241.20000000000002deg, #45cba5, #27a39b);}
  68% { background: linear-gradient(244.8deg, #45cba5, #27a39b);}
  69% { background: linear-gradient(248.4deg, #45cba5, #27a39b);}
  70% { background: linear-gradient(252deg, #45cba5, #27a39b);}
  71% { background: linear-gradient(255.6deg, #45cba5, #27a39b);}
  72% { background: linear-gradient(259.2deg, #45cba5, #27a39b);}
  73% { background: linear-gradient(262.8deg, #45cba5, #27a39b);}
  74% { background: linear-gradient(266.40000000000003deg, #45cba5, #27a39b);}
  75% { background: linear-gradient(270deg, #45cba5, #27a39b);}
  76% { background: linear-gradient(273.6deg, #45cba5, #27a39b);}
  77% { background: linear-gradient(277.2deg, #45cba5, #27a39b);}
  78% { background: linear-gradient(280.8deg, #45cba5, #27a39b);}
  79% { background: linear-gradient(284.40000000000003deg, #45cba5, #27a39b);}
  80% { background: linear-gradient(288deg, #45cba5, #27a39b);}
  81% { background: linear-gradient(291.6deg, #45cba5, #27a39b);}
  82% { background: linear-gradient(295.2deg, #45cba5, #27a39b);}
  83% { background: linear-gradient(298.8deg, #45cba5, #27a39b);}
  84% { background: linear-gradient(302.40000000000003deg, #45cba5, #27a39b);}
  85% { background: linear-gradient(306deg, #45cba5, #27a39b);}
  86% { background: linear-gradient(309.6deg, #45cba5, #27a39b);}
  87% { background: linear-gradient(313.2deg, #45cba5, #27a39b);}
  88% { background: linear-gradient(316.8deg, #45cba5, #27a39b);}
  89% { background: linear-gradient(320.40000000000003deg, #45cba5, #27a39b);}
  90% { background: linear-gradient(324deg, #45cba5, #27a39b);}
  91% { background: linear-gradient(327.6deg, #45cba5, #27a39b);}
  92% { background: linear-gradient(331.2deg, #45cba5, #27a39b);}
  93% { background: linear-gradient(334.8deg, #45cba5, #27a39b);}
  94% { background: linear-gradient(338.40000000000003deg, #45cba5, #27a39b);}
  95% { background: linear-gradient(342deg, #45cba5, #27a39b);}
  96% { background: linear-gradient(345.6deg, #45cba5, #27a39b);}
  97% { background: linear-gradient(349.2deg, #45cba5, #27a39b);}
  98% { background: linear-gradient(352.8deg, #45cba5, #27a39b);}
  99% { background: linear-gradient(356.40000000000003deg, #45cba5, #27a39b);}
  100% { background: linear-gradient( 0deg, #45cba5, #27a39b);}
}

.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
